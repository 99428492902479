import { IProject } from "@interfaces";

export const projects: IProject[] = [
  {
    id: 0,
    name: "Portfolio",
    slug: "portfolio",
    description:
      "The front end for my online portfolio and blog. Written in React, Typescript, and Bootstrap with a rest API on the backend. Data is prefetched and cached using Axios and React-query. Currently deployed on Netlify.",
    thumbnail: "thumbnail_likrdz",
    website: "https://www.adamnicol.dev",
    github: "https://github.com/adamnicol/portfolio",
    complete: true,
    tags: [
      { id: 1, name: "react" },
      { id: 2, name: "typescript" },
      { id: 3, name: "bootstrap" },
    ],
    features: [
      "Written using React and Typescript",
      "Uses Bootstrap and SCSS for styling",
      "Connects to a rest API using Axios",
      "Data preloaded and cached using React-query",
      "Authentication with JWT access/refresh tokens",
      "Form validation using React-hook-form and Zod",
      "Unit tests with Jest and React testing library",
      "Error logging using Sentry.io",
      "Images are served from the Cloudinary CDN",
    ],
    images: [
      "home_wbefye",
      "article_wbtemh",
      "about_wauvdj",
      "projects_jzvbld",
      "experience_ntxi0s",
      "contact_bpwcdn",
      "register_srlq82",
    ],
  },
  {
    id: 1,
    name: "NodeJS Rest API",
    slug: "nodejs_rest_api",
    description:
      "A Node/Express rest API written in Typescript that serves as the backend for my site. Featuring authentication using JWT tokens, schema validation using Zod, and connecting to a PostgreSQL database using the Prisma ORM. Currently deployed using Docker.",
    thumbnail: "thumbnail_mibmz3",
    website: "https://api.adamnicol.dev",
    github: "https://github.com/adamnicol/portfolio",
    complete: true,
    tags: [
      { id: 1, name: "node" },
      { id: 2, name: "express" },
      { id: 3, name: "typescript" },
      { id: 4, name: "prisma" },
      { id: 5, name: "postgres" },
    ],
    features: [
      "Written using Express.js and Typescript",
      "Uses the Prisma ORM with PostgreSQL",
      "Schema validation using Zod",
      "Authentication with JWT tokens",
      "Passwords are hashed using bcrypt",
      "Nodemailer and handlebars for emails",
      "Rate limiting for login and account creation",
      "Documentation with Swagger and JSDoc",
      "Error logging using Sentry.io",
      "Deployed in a Docker container",
    ],
    images: ["swagger_thppju"],
  },
  {
    id: 2,
    name: "Spaced",
    slug: "spaced",
    description:
      "Spaced is a new project I am building using NextJS. Spaced will allow users to design their own digital flashcards for studying and language learning. Spaced repetition algorithms will determine which cards have not been practiced in a while.",
    thumbnail: "thumbnail_t2ihpp",
    website: "https://github.com/adamnicol/spaced",
    github: "https://github.com/adamnicol/spaced",
    complete: false,
    tags: [
      { id: 1, name: "nextjs" },
      { id: 2, name: "typescript" },
      { id: 3, name: "mantine" },
      { id: 4, name: "prisma" },
      { id: 5, name: "postgres" },
    ],
    features: [
      "Built using NextJS and Typescript",
      "Mantine for UI controls",
      "Uses the Prisma ORM with PostgreSQL",
      "Authentication using NextAuth",
      "Nodemailer and handlebars for emails",
      "Error logging using Sentry.io",
      "Deployed on Vercel and Supabase",
    ],
    images: [],
  },
];
